import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      stasiLogo: file(relativePath: { eq: "stasi-logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  if (!data?.stasiLogo?.childImageSharp?.fluid) {
    return <div>Immagine non trovata!</div>
  }

  return <Img fluid={data.stasiLogo.childImageSharp.fluid} />
}

export default Image
