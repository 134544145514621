import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer
    style={{
        background: `lightgray`,
        marginTop: `1.45rem`,
      }}
  >

    <div
      style={{
        'text-align': `center`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h6>
   <Link to="/privacy-policy/">Cookie & Privacy Policy</Link> <br /> <br />

    © {new Date().getFullYear()} All rights reserved<br />Stasi Group SRL <br /><br /> <a  href="https://www.biztems.it" target="_blank" rel="noopener noreferrer">Made in Biztems</a><br/>
    </h6></div>

  </footer>
)

export default Footer
